import { useState, useEffect } from 'react'
import {
  QueryClient, useQueryClient, useQuery,
} from 'react-query'
import { useRouter } from 'next/router'
import {
  getCourseListService, getCourseService, getBadgeService,
  getPaidCourseListService, getLearningPlanService, getLearningStats,
} from 'services/course'
import { useCountryCode } from './payment'
import useSession from './session'

export async function prefetchCourses(queryClient) {
  const token = ''
  await queryClient.prefetchQuery(
    ['GET_ALL_COURSES', 'MX', token],
    () => getCourseListService('MX', token),
  )
  return queryClient
}

export function useCourses() {
  const queryClient = useQueryClient()
  const { token } = useSession()
  const { data: { countryCode } } = useCountryCode()
  const coursesData = useQuery(
    ['GET_ALL_COURSES', countryCode, token],
    () => getCourseListService(countryCode, token),
    {
      placeholderData: () => queryClient.getQueryData(['GET_ALL_COURSES', 'MX', '']),
      refetchOnMount: false,
    },
  )
  return { ...coursesData, countryCode }
}

export async function prefetchSmallCoursesList({ queryClient, countryCode }) {
  const is_mexican = countryCode === 'MX'
  await queryClient.prefetchQuery(
    ['COURSES_PAID_LIST', is_mexican],
    () => getPaidCourseListService({ countryCode }),
  )
  return queryClient
}

export async function prefetchLearningPlan({ queryClient }) {
  const token = ''
  await queryClient.prefetchQuery(
    ['LEARNING_PLAN', token],
    () => getLearningPlanService({ token }),
  )
  return queryClient
}


export function useLearningPlan() {
  const { token } = useSession()
  const queryClient = useQueryClient()

  return useQuery(
    ['LEARNING_PLAN', token],
    () => getLearningPlanService({ token }),
    {
      placeholderData: () => queryClient.getQueryData(['LEARNING_PLAN', '']),
    },
  )
}

export function useLearningStats() {
  const { token } = useSession()

  return useQuery(
    'LEARNING_STATS',
    () => getLearningStats({ token }),
    {
      enabled: !!token,
    },
  )
}

export function usePaidCourses() {
  const { data: { countryCode } } = useCountryCode()
  const is_mexican = countryCode === 'MX'
  return useQuery(
    ['COURSES_PAID_LIST', is_mexican],
    () => getPaidCourseListService({ countryCode }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
}

export function useCourseKey() {
  const router = useRouter()
  const { query } = router
  const courseSlug = query.course || query.course_slug
  const couponCode = query.coupon_code || query.single_coupon || null
  const { token } = useSession()
  const { data: { countryCode } } = useCountryCode()
  return ['GET_COURSE', courseSlug, countryCode, token, couponCode]
}

export function useCourseWithSavedCoupon(courseByParam) {
  const router = useRouter()
  const { query } = router
  const courseSlug = courseByParam || query.course || query.course_slug
  const couponCode = query.coupon_code || query.single_coupon || null
  const [courseCoupon, setCourseCoupon] = useState(couponCode)

  useEffect(() => {
    const existentCoupon = sessionStorage.getItem('singleCoupon')
    if (existentCoupon) {
      setCourseCoupon(existentCoupon)
    }
    if (!existentCoupon && query.single_coupon) {
      setCourseCoupon(query.single_coupon)
      sessionStorage.setItem('singleCoupon', query.single_coupon)
    }
  }, [query.single_coupon])

  const { token } = useSession()
  const { data: { countryCode } } = useCountryCode()
  const queryClient = useQueryClient()
  return useQuery(
    ['GET_COURSE', courseSlug, countryCode, token, courseCoupon],
    () => getCourseService({
      course_slug: courseSlug,
      coupon_code: courseCoupon,
      token,
      countryCode,
    }),
    {
      enabled: !!courseSlug,
      placeholderData: () => {
        const temporalCode = countryCode === 'MX' ? 'MX' : 'US'
        return queryClient.getQueryData(['GET_COURSE', courseSlug, temporalCode, '', null])
      },
      // staleTime: 1000 * 60 * 60,
      // REMOVE THIS
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
}

export function useCourse(courseByParam) {
  const router = useRouter()
  const { query } = router
  const courseSlug = courseByParam || query.course || query.course_slug

  const courseCoupon = query.coupon_code || query.single_coupon || null

  const { token } = useSession()
  const { data: { countryCode } } = useCountryCode()
  const queryClient = useQueryClient()
  return useQuery(
    ['GET_COURSE', courseSlug, countryCode, token, courseCoupon],
    () => getCourseService({
      course_slug: courseSlug,
      coupon_code: courseCoupon,
      token,
      countryCode,
    }),
    {
      enabled: !!courseSlug,
      placeholderData: () => queryClient.getQueryData(['GET_COURSE', courseSlug, '', '', null]),
      // staleTime: 1000 * 60 * 60,
      // REMOVE THIS
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
}

export async function prefetchCourse({
  slug, client, token: serverToken, country,
}) {
  const countryCode = country || ''
  const token = serverToken || ''
  const couponCode = null
  const queryClient = client || new QueryClient()
  await queryClient.prefetchQuery(
    ['GET_COURSE', slug, countryCode, token, couponCode],
    () => getCourseService({
      course_slug: slug,
      coupon_code: couponCode,
      token,
      countryCode,
    }),
  )
  return queryClient
}

export async function prefetchBadge({ queryClient, course, serverToken }) {
  const token = serverToken || ''
  await queryClient.prefetchQuery(
    ['GET_BADGE', course, token],
    () => getBadgeService({
      course,
      token,
    }),
  )
}

export function useBadge() {
  const { query } = useRouter()
  const course = query.course || query.course_slug
  const { token } = useSession()
  const queryClient = useQueryClient()
  return useQuery(
    ['GET_BADGE', course, token],
    () => getBadgeService({
      course,
      token,
    }),
    {
      enabled: !!course,
      placeholderData: () => queryClient.getQueryData(['GET_BADGE', course, '']),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )
}
