import handlePromise from 'utils/handle-promise'

export async function getCourseListService(countryCode, token) {
  const url = `/api/v1/courses/?country_code=${countryCode}`
  const data = await handlePromise(url, {
    method: 'GET',
    headers: { Authorization: token },
  }, true)
  return data
}

export async function getLearningPlanService({ token }) {
  const url = '/api/v1/learning-path/'
  const data = await handlePromise(url, {
    method: 'GET',
    headers: { Authorization: token },
  }, true)
  return data
}

export async function createCourseEnrolmentService({ course, token }) {
  const url = `/api/v1/courses/${course}/create-enrollment/`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: { Authorization: token },
  })
  return data
}

// TODO: RES
export async function getCourseService({
  req = null, res = null, course_slug, token, countryCode, coupon_code,
}) {
  const countryCodeQuery = countryCode ? `?country_code=${countryCode}` : ''
  const couponCodeQuery = coupon_code ? `&coupon_code=${coupon_code}` : ''
  const url = `/api/v1/courses/${course_slug}${countryCodeQuery}${couponCodeQuery}`

  const data = await handlePromise(url, {
    method: 'GET',
    headers: { Authorization: token },
  })

  return data
}

export async function getBadgeService({ course, token }) {
  const url = `/api/v1/badges/${course}/`
  const data = await handlePromise(url, {
    headers: { Authorization: token },
  })
  return data
}

export async function getPaidCourseListService({  countryCode }) {
  const url = `/api/v1/courses/paid/?country_code=${countryCode}`
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}

export async function getLearningStats({ token }) {
  const url = '/api/v1/me/stats/'
  const data = await handlePromise(url, {
    method: 'GET',
    headers: { Authorization: token },
  })
  return data
}
