import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { FaChevronDown } from 'react-icons/fa'

const MenuSectionStyled = styled.details`
  user-select: none;
  &:hover .menu-item {
    opacity: 0.65;
  }
  &[open] {
    background: var(--neutral90);
    summary svg {
      transform: rotate(180deg);
    }
  }
  summary::marker,
  summary::-webkit-details-marker {
    display: none;
    color: transparent;
  }
  summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline-end: 16px;
  }
  .menu-item {
    padding: 16px;
    cursor: pointer;
    font: var(--regularBold);
    display: block;
  }
  .menu-options {

  }
  .option {
    background: var(--neutral90);
    &-container {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &-title {
      font: var(--p2Bold);
    }
    &-subtitle {
      display: none;
    }
  }
  ${({ important }) => important && 'position: relative;'}
  .important {
    width: 10px;
    height: 10px;
    background: var(--secondary);
    position: absolute;
    border-radius: 50%;
    top: calc(50% - 5px);
    left: 110px;
    box-shadow: 0 0 12px 3px #ff8abb;
    animation: important cubic-bezier(0.54, 0.04, 0.44, 1.01) 0.5s infinite;
    animation-direction: alternate;
  }
  @media(min-width: 1024px){
    position: relative;
    .important {
      left: 3px;
    }
    summary {
      padding-inline-end: 0px;
    }
    summary svg {
      display: none;
    }
    .menu-options {
      transition: 0.3s ease-in opacity;
      visibility: hidden;
      opacity: 0;
      width: max-content;
      position: absolute;
      padding: 24px;
      left: -32px;
      border-radius: 8px;
      overflow: hidden;
      filter: drop-shadow(0px 2px 24px rgba(97, 112, 216, 0.4));
      background: var(--neutral90);
    }
    &[open]::before {
      content: "";
      position: absolute;
      max-width: 14px;
      min-height: 8px;
      z-index: 1;
      clip-path: polygon(50% 0, 0 100%, 100% 100%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: 0 auto;
      background: yellow;
      color: red;
      font-weight: bold;
      background: var(--neutral90);
    }
    &[open] {
      background: transparent;
      .menu-options {
        visibility: visible;
        opacity: 1;
      }
    }
    .option {
      background: var(--neutral90);
      &-container {
        padding: 12px 0;
      }
    }
    .menu-item {
      font: var(--p2Bold);
      padding: 12px 24px;
    }
    .option {
      &:hover .option-title {
        color: var(--turquoise);
        cursor: pointer;
      }
      &-subtitle {
        font: var(--smallRegular);
        display: block;
      }
    }
  }
`

function MenuSection({ title, id, options, link, important }) {
  const optionRef = useRef(null)

  const addOpen = () => {
    optionRef.current?.setAttribute('open', true)
  }
  const removeOpen = () => {
    optionRef.current?.removeAttribute('open')
  }

  useEffect(() => {
    if (window.matchMedia('(min-width: 1024px)').matches && optionRef.current) {
      optionRef.current.addEventListener('mouseover', addOpen)
      optionRef.current.addEventListener('mouseout', removeOpen)
    }
    return () => {
      optionRef?.current?.removeEventListener('mouseover', addOpen)
      optionRef?.current?.removeEventListener('mouseout', removeOpen)
    }
  }, [optionRef])

  if (link && !options) {
    return (
      <MenuSectionStyled as="div" important={important}>
        <Link href={link} className="menu-item single-link" id={id}>
          {important && <span className="important" />}
          {title}
        </Link>
      </MenuSectionStyled>
    )
  }

  return (
    <MenuSectionStyled ref={optionRef}>
      <summary>
        <Link href={link} className="menu-item" id={id}>
          {title}
        </Link>
        <FaChevronDown color="inherit" size="14px" />
      </summary>
      <ul className="menu-options">
        {options.map((option) => (
          <li className="option" key={option.id}>
            <Link href={option.path} className="option-container" id={option.id}>
              <div className="option-icon">
                <img width="32" height="32" src={option.icon} alt={option.title} />
              </div>
              <div>
                <p className="option-title">{option.title}</p>
                <p className="option-subtitle">{option.subtitle}</p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </MenuSectionStyled>
  )
}

export default MenuSection
