import { useState } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { MdClose, MdMenu } from 'react-icons/md'
import useSession from 'querys/session'
import useViewportHeight from 'hooks/use-viewport-height'
import CountDownBar from 'common/navigation/count-down-bar'
import CountDownSpacer from 'common/count-down/count-down-spacer'
import Menu, { MenuStyled } from './menu'
import UserMenu from './user-menu'

const NavigationStyled = styled.div`
  top: 0px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 5;
  background: var(--sky);
  /* position: sticky; */
  /* z-index: 3; */
  /* top: 0; */
  .count-down-bar {
    display: none;
  }
  .navigation {
    position: sticky;
    z-index: 1;
    display: flex;
    padding: 1em;
    align-items: center;
    justify-content: space-between;
    padding: 1em 4em 1em 1em;
    block-size: calc(var(--headerBlockSize) - 2rem);
  }
  .toogle-button {
    text-align: right;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    padding-inline-start: 1rem;
    > * {
      display: inline;
      vertical-align: middle;
    }
    .close {
      display: none;
    }
  }
  .checkbox {
    display: none;
  }
  .checkbox:checked {
    border: 1px solid var(--error);
  }
  .checkbox:checked {
    ~ .toogle-button  {
      .close {
        display: inline;
      }
      .open {
        display: none;
      }
    }
    ~ .user {
      display: none;
    }
    ~ ${MenuStyled} {
      transform: translateX(0);
      overflow-y: hidden;
    }
  }
  .logo {
    width: auto;
    height: 33px;
    z-index: 1;
  }
  .menu {
    transform: translateX(100%);
    transition: 0.3s transform;
  }
  @media(min-width: 1024px) {
    position: sticky;
    .navigation {
      position: relative;
      margin: 0 auto;
      max-inline-size: ${({ maxWidth }) => (maxWidth || '1200px')};
      padding: 1em 3em;
      ${({ fullWidth }) => fullWidth && `
        max-inline-size: 100vw;
      `};
    }
    .count-down-bar {
      position: static;
      display: block;
    }
    .toogle-button {
      display: none;
    }
  }
  @media(min-width: 1366px) {
    .navigation {
      padding: 1em 5em;
    }
  }
`

const MobileCountDownbar = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

function Navigation({ noCountdown, fullWidth, maxWidth }) {
  const { token } = useSession()
  const [countDownOpen, setCountDownOpen] = useState(true)
  const viewportHeight = useViewportHeight()
  return (
    <>
      <MobileCountDownbar>
        {(!noCountdown) && (
          <>
            <CountDownBar setCountDownOpen={setCountDownOpen} />
          </>
        )}
      </MobileCountDownbar>
      <NavigationStyled fullWidth={fullWidth} maxWidth={maxWidth}>
        <div className="count-down-bar" id="countdown-bar">
          {(!noCountdown) && (
            <>
              <CountDownBar setCountDownOpen={setCountDownOpen} />
            </>
          )}
        </div>
        <div className="navigation">
          <Link href="/" id="h-home" className="logo">
            <img
              width="53.84"
              height="40"
              src="/icons/icon-105x78.png"
              className="logo"
              alt="El logotipo de la plataforma, un cohete formado por dos triangulos de diamante"
            />
          </Link>
          <input className="checkbox" type="checkbox" id="toogle-button" name="active" />
          <label htmlFor="toogle-button" className="toogle-button">
            <MdMenu className="open" size={30} color="var(--secondary)" />
            <MdClose className="close" size={30} color="var(--secondary)" />
          </label>
          {/* <div className="menu"> */}
          <MenuStyled minHeight={viewportHeight} token={token}>
            <Menu />
          </MenuStyled>
          {/* </div> */}
          <div className="user">
            <UserMenu />
          </div>
        </div>
      </NavigationStyled>
      {!noCountdown && <CountDownSpacer open={countDownOpen} />}
    </>
  )
}

export default Navigation
