import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useSession from 'querys/session'
import UserSession from 'common/user-session'
import { SecondaryButton, ButtonOutline } from 'common/button'

const AuthStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  .register {
    display: none
  }
  @media (min-width: 768px) {
    margin: 0;
    grid-template-columns: repeat(2, min-content);
    column-gap: 16px;
    .register {
      display: block;
    }
  }
  @media screen and (min-width: 1024px) {
    row-gap: 16px;
  }
`

function UserMenu() {
  const { asPath } = useRouter()
  const { token, isStale } = useSession()
  const redirectWithoutNext = asPath === '/'

  if (isStale) return null

  if (!token) {
    return (
      <AuthStyled>
        <div className="register">
          <Link passHref prefetch={false} href={redirectWithoutNext ? '/registro' : `/registro?next=${asPath}`}>
            <ButtonOutline fontWeight="600" radius={4} height="40" id="register" padding="10px 16">
              Regístrate
            </ButtonOutline>
          </Link>
        </div>
        <div className="login">
          <Link passHref prefetch={false} href={redirectWithoutNext ? '/login' : `/login?next=${asPath}`}>
            <SecondaryButton fontWeight="500" id="login" height="40" radius={4} padding="10.5px 16">
              Iniciar sesión
            </SecondaryButton>
          </Link>
        </div>
      </AuthStyled>
    )
  }

  return (
    <UserSession />
  )
}

export default UserMenu
