import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useSession from 'querys/session'
import { NeutralButtonOutline, SecondaryButton } from 'common/button'
import menuitems from './menu.json'
import MenuSection from './menu-section'

export const MenuStyled = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  overflow-y: auto;
  padding-block-start: var(--headerBlockSize);
  bottom: 0;
  min-block-size: ${({ minHeight }) => minHeight};
  max-block-size: ${({ minHeight }) => minHeight};
  box-sizing: border-box;
  background: var(--sky);
  transform: translateX(100%);
  transition: 0.3s transform;
  display: grid;
  grid-template-rows: 1fr;
  .menu-sections {
    overflow: auto;
  }
  ${({ token }) => !token && `
    grid-template-rows: 1fr min-content;
  `}
  .session-btns {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
  @media screen and (min-width: 1024px) {
    transform: initial;
    max-width: initial;
    position: initial;
    padding-block-start: 0;
    display: block;
    height: initial;
    min-block-size: initial;
    max-block-size: initial;
    background-color: initial;
    overflow: visible;
    flex: 1;
    margin: 0 24px;
    .session-btns {
      display: none;
    }
    .menu-sections {
      overflow: visible;
      display: flex;
    }
  }
`

function Menu() {
  const { asPath, pathname } = useRouter()
  const { token } = useSession()
  const redirectWithNext = asPath === '/' || asPath.includes('cursos') || pathname === '/404'
  return (
    <>
      <div className="menu-sections">
        {menuitems.map((props) => <MenuSection key={props.id} {...props} />)}
      </div>
      {!token ? (
        <div className="session-btns">
          <Link prefetch={false} href={redirectWithNext ? '/login' : `/login?next=${asPath}`}>
            <SecondaryButton fullWidth fontWeight="500" radius={4} id="login-mobile">Iniciar sesión</SecondaryButton>
          </Link>
          <Link prefetch={false} href={redirectWithNext ? '/registro' : `/registro?next=${asPath}`}>
            <NeutralButtonOutline fullWidth fontWeight="600" radius={4} id="register-mobile">Regístrate</NeutralButtonOutline>
          </Link>
        </div>
      ) : null}
    </>
  )
}

export default Menu
